// Include this in index.html before other files in this directory.
/*global angular: false */

let am = angular.module('mtz-directives', ['app-share', 'ui.bootstrap', 'ui.router']);

am.value('mtzMomentaryTooltip',
  (jq, text, side, ms) => {
    jq.tooltip({placement: side, title: text});
    jq.tooltip('show');
    setTimeout(() => jq.tooltip('destroy'), ms);
  });
